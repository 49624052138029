import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    systemThemeMediaQuery: any;
    public isDarkMode = false;
    public iconName: 'system' | 'dark' | 'light' = 'system';
    private isDarkTheme!: boolean;

    constructor(@Inject(DOCUMENT) private document: Document) {
        if (window) {
            this.systemThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            this.isDarkTheme = this.systemThemeMediaQuery?.matches;

            this.systemThemeMediaQuery.addEventListener('change', () => {
                const storedPreference = localStorage.getItem('themePreference');

                if (this.iconName === 'system' && !storedPreference) {
                    this.isDarkMode = this.systemThemeMediaQuery.matches;
                    this.applyTheme(this.isDarkMode);
                }
            });
        }
    }

    init(): void {
        const storedPreference = localStorage.getItem('themePreference');

        if (storedPreference) {
            this.iconName = storedPreference as 'dark' | 'light' | 'system';
            this.isDarkMode =
                storedPreference === 'dark' ? true : storedPreference === 'light' ? false : this.isDarkTheme;
        } else {
            this.iconName = 'system';
            this.isDarkMode = this.isDarkTheme;
        }

        this.applyTheme(this.isDarkMode);
    }

    toggleTheme(theme: 'dark' | 'light' | 'system'): void {
        this.iconName = theme;

        if (theme === 'system') {
            this.isDarkMode = this.isDarkTheme;
            localStorage.removeItem('themePreference');
        } else {
            this.isDarkMode = theme === 'dark';
            localStorage.setItem('themePreference', theme);
        }

        this.applyTheme(this.isDarkMode);
    }

    private applyTheme(isDarkMode: boolean): void {
        const root = this.document.documentElement;

        const darkThemeVariables = {
            '--variable-color-white': '#0F0F0F',
            '--variable-background-color': '#0F0F0F',
            '--variable-color-off-white': '#49454f',
            '--variable-color-light-white': '#222121',
            '--variable-color-gray': '#272424',
            '--variable-color-off-black': '#bfbfbf',
            '--variable-color-black': '#ffffff',
            '--variable-color-green': '#3bb273',
            '--variable-color-light-green': '#4cd964',
            '--variable-color-off-grren': '#3fc063',
            '--variable-color-golden-yellow': '#e0a800',
            '--variable-color-orange': '#d87c1f',
            '--variable-color-blue': '#0061FE',
            '--variable-color-danger': '#b71c1c',
            '--variable-color-error': '#c62828',
            '--variable-color-disable': '#5f5f5f',
            '--variable-color-drop-down-box-shadow': '0 2px 4px 0 rgba(255, 255, 255, 0.1)',
            '--variable-color-light-gray': '#1e1e1e',
            '--skeleton-ui-background': '#222121',
            '--variable-color-light-black': '#808080',
            '--material-switch-after-color': '#565656',
            '--material-switch-before-color': '#565656',
            '--variable-border-light-color': '#3a3f44',
        };

        const lightThemeVariables = {
            '--variable-color-white': '#fff',
            '--variable-background-color': '#fff',
            '--variable-color-off-white': '#dfdfdf',
            '--variable-color-light-white': '#f5f5f5',
            '--variable-color-gray': '#d8d8d8',
            '--variable-color-off-black': '#49454f',
            '--variable-color-black': '#000',
            '--variable-color-green': '#3fc063',
            '--variable-color-light-green': '#4cd964',
            '--variable-color-off-grren': '#3bb273',
            '--variable-color-golden-yellow': '#ee9500',
            '--variable-color-orange': '#ef9621',
            '--variable-color-blue': '#0061fe',
            '--variable-color-danger': '#f44336',
            '--variable-color-error': '#ff3b30',
            '--variable-color-disable': '#bfbfbf',
            '--variable-color-drop-down-box-shadow': '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            '--variable-color-light-gray': '#eef2f6',
            '--skeleton-ui-background': '#f5f5f5',
            '--variable-color-light-black': '#808080',
            '--material-switch-after-color': '#d9d9d9',
            '--material-switch-before-color': '#e0e0e0',
            '--variable-border-light-color': '#e9edf1',
        };

        const themeVariables = isDarkMode ? darkThemeVariables : lightThemeVariables;

        for (const [key, value] of Object.entries(themeVariables)) {
            root.style.setProperty(key, value);
        }
    }
}
