import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IntercomService } from './service/intercom.service';
import { environment } from 'src/environments/environment';
import { CustomerService } from './service/customer.service';
import { ThemeService } from './service/theme.service';

declare let intercomSettings: any;
declare const Intercom: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'webapp';
    recapchaKey = environment.recaptchaEnterpriseKey;
    response: any;

    @ViewChild('captchaRef') captchaRef!: any;
    constructor(
        private intercomService: IntercomService,
        private router: Router,
        private customerService: CustomerService,
        private themeService: ThemeService
    ) {}
    ngOnInit(): void {
        this.themeService.init();
        if (localStorage.getItem('user_hash')) {
            const path = window.location.href;
            if (
                !path.includes('/auth') &&
                !path.includes('/donate') &&
                !path.includes('/promote') &&
                !path.includes('/register')
            )
                this.intercomService.boot();
        }
        if (localStorage.getItem('user_id') && localStorage.getItem('customer_id')) {
            this.customerService.checkIsUserOwner().then(() => {
                // console.log('USER IS OWNER=====', this.customerService.isOwner);
            });
        }
    }
}
